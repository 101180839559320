import VideoScrubber from "../VideoScrubber";
import Controls0 from "./ai-artist";
import Controls2 from "./photo-op";
import Controls11 from "./life";
import Controls12 from "./graduation";
import Controls18 from "./wind";
import Controls19 from "./pong4";
import Controls26 from "./butterfly";
import Controls32 from "./ifs";
import Controls33 from "./stylegan";
import Controls35 from "./style-transfer";
import Controls36 from "./hilbert";
import Controls39 from "./sorting";
import Controls40 from "./bus-bunching";
import Controls49 from "./temple-photo-op";
import Controls55 from "./maze-generation";
const controls: Map<string, () => JSX.Element> = new Map([
  ["ai-artist", Controls0],
["salt", VideoScrubber],
["photo-op", Controls2],
["grendel", VideoScrubber],
["estefan", VideoScrubber],
["life", Controls11],
["graduation", Controls12],
["cenote", VideoScrubber],
["witchscat", VideoScrubber],
["wind", Controls18],
["pong4", Controls19],
["papa", VideoScrubber],
["owned", VideoScrubber],
["pajama-gladiator", VideoScrubber],
["butterfly", Controls26],
["studacc", VideoScrubber],
["taijitu", VideoScrubber],
["ifs", Controls32],
["stylegan", Controls33],
["death", VideoScrubber],
["style-transfer", Controls35],
["hilbert", Controls36],
["rams-horn", VideoScrubber],
["sorting", Controls39],
["bus-bunching", Controls40],
["stowaway", VideoScrubber],
["metro", VideoScrubber],
["temple-photo-op", Controls49],
["sheriff", VideoScrubber],
["maze-generation", Controls55]
]);
export default controls;
